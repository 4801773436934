import { Link, NavLink, useLocation } from "react-router-dom";
import LogoIcon1 from "../../assets/img/Logo1.png";
import ScoreIcon from "../../assets/img/score-icon.png";
import Scorecard from "../../assets/img/Scorecard.png";
import BloodIcon from "../../assets/img/Blood-icon.png";
import SideLogo from "../../assets/img/Logo.png";
import Logout from "../../assets/img/box-arrow-right.svg";
interface IProps {
  setSigninStatus: any;
}

const Header = ({ setSigninStatus }: IProps) => {
  const location = useLocation();
  function closeNav() {
    document
      .getElementById("nav-icon1")
      ?.setAttribute("aria-expanded", "false");
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }

  const logOut = () => {
    sessionStorage.removeItem("AUTH_TOKEN");
    setSigninStatus(false);
  };
  return (
    <>
      <div className="side-dashboard">
        <div className="logo-section">
          <img className="sidebar_logo" src={SideLogo} alt="" />
          <div className="sidebar-ul">
            <ul>
              <li className="listitem">
                <NavLink
                  to="/scoreboard"
                  className={({ isActive }) => {
                    return isActive
                      ? "active sidebar-anchor"
                      : "sidebar-anchor";
                  }}
                >
                  <img className="sidebar-icons" src={ScoreIcon} alt="" />
                  <span>Scorecard</span>
                </NavLink>
              </li>

              <li className="listitem">
                <NavLink
                  to="/kpi"
                  className={({ isActive }) => {
                    return isActive
                      ? "active sidebar-anchor"
                      : "sidebar-anchor";
                  }}
                >
                  <img className="sidebar-icons" src={Scorecard} alt="" />
                  <span>KPI</span>
                </NavLink>
              </li>
              <li className="listitem">
                <NavLink
                  to="/checkup-result"
                  className={({ isActive }) => {
                    return isActive
                      ? "active sidebar-anchor"
                      : "sidebar-anchor";
                  }}
                >
                  <img className="sidebar-icons" src={BloodIcon} alt="" />
                  <span>Blood Results</span>
                </NavLink>
              </li>
              <li className="listitem" onClick={() => logOut()}>
                <a
                
                  className={`sidebar-anchor`}
                >
                  <img
                    className="sidebar-icons logoutIcon"
                    src={Logout}
                    alt=""
                  />
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <!---mobile header view ---> */}

      <div className="mobileheader">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/scoreboard">
              <img src={LogoIcon1} alt="" />
            </Link>
            <span className="scorecard">
              {location.pathname.includes("scoreboard")
                ? "Scorecard"
                : location.pathname.includes("kpi")
                ? "KPI"
                : "Blood Results"
                }
            </span>
            <a
              id="nav-icon1"
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon test1"></span>
              <span className="navbar-toggler-icon test2"></span>
            </a>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul>
                <li className="listitem" onClick={() => closeNav()}>
                  <NavLink
                    to="/scoreboard"
                    className={({ isActive }) => {
                      return isActive
                        ? "active sidebar-anchor "
                        : "sidebar-anchor";
                    }}
                  >
                    <img className="sidebar-icons" src={ScoreIcon} alt="" />
                    <span>Scorecard</span>
                  </NavLink>
                </li>

                <li className="listitem" onClick={() => closeNav()}>
                  <NavLink
                    to="/kpi"
                    className={({ isActive }) => {
                      return isActive
                        ? "active sidebar-anchor"
                        : "sidebar-anchor";
                    }}
                  >
                    <img className="sidebar-icons" src={Scorecard} alt="" />
                    <span>KPI</span>
                  </NavLink>
                </li>

                <li className="listitem" onClick={() => closeNav()}>
                  <NavLink
                    to="/checkup-result"
                    className={({ isActive }) => {
                      return isActive
                        ? "active sidebar-anchor"
                        : "sidebar-anchor";
                    }}
                  >
                    <img className="sidebar-icons" src={BloodIcon} alt="" />
                    <span>Blood Results</span>
                  </NavLink>
                </li>
                <li className="listitem">
                  <a className="sidebar-anchor"
                  onClick={()=>logOut()}
                  >
                  <img
                      className="sidebar-icons logoutIcon"
                      src={Logout}
                      alt=""
                    />
                    <span>Logout</span>
                  </a> 
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
