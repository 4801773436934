import { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Header from "../component/header/Header";
import Login from "../component/login/Login";
import BloodResultMain from "../pages/BloodResult/BloodTestMain/BloodResultMain";
import BloodResultChildPage from "../pages/BloodResult/BloodTestChild/BloodResultChild";
import KpiPage from "../pages/KPI/Kpi.Page";
import ScoreChild from "../pages/scoreboard/scoreChild/ScoreChild";
import ScoreBoard from "../pages/scoreboard/ScoreMain/ScoreBoardMain";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

const RoutePath = () => {
  const [isVerified, setVerified] = useState(sessionStorage.getItem("AUTH_TOKEN") ? true : false);
  const navigate = useNavigate();
  const setSigninStatus = (status: boolean) => {
    setVerified(status);
    if(status){
      navigate('/scoreboard')
    }
  };


  return (
    <>
      <div className={isVerified ? "wrapper" : ""}>
        {isVerified ? <Header setSigninStatus={setSigninStatus}/> : <></>}

        <Routes>
          <Route path="/" element={<Navigate to="/scoreboard" />} />
          <Route
            path="/scoreboard"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <ScoreBoard/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/scoreboard/:itemId"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <ScoreChild  />
              </ProtectedRoute>
            }
          />
          <Route
            path="/kpi"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <KpiPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkup-result"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <BloodResultMain />
              </ProtectedRoute>
            }
          />
           <Route
            path="/checkup-result-detail"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <BloodResultChildPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute isVerified={isVerified} redirectPath="/scoreboard">
                <Login setSigninStatus={setSigninStatus}/>
              </PublicRoute>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default RoutePath;
