import "./scoreboardmain.style.scss";
import ProgressBar from "../../../shared/ProgressBar/ProgressBar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SCORE_CARD } from "../../../services/EndPoints";
import { HttpCallGet } from "../../../services/NetworkCall";
import Spinner from "../../../shared/Spinner/Spinner";

const ScoreBoard = () => {
  const [userData, setUserData] = useState<any>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [epigenticAge, setEpigenticAge] = useState<any>();

  const navigateWithData = (data?: any) => {
    navigate(`/scoreboard/${data}`);
  };

  const getScoreBoaradDetails = () => {
    setLoading(true);
    HttpCallGet(SCORE_CARD, sessionStorage.getItem("AUTH_TOKEN"))
      .then((response: any) => {
        if (response.data.status === 200) {
          setUserData(response.data.data);
          sessionStorage.setItem("scoreCardId",response.data.data.id);
          DobToAgeConverter(response.data.data.dateOfBirth);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  /**
   *
   * @param dateString
   * @returns ages  and @setEpigenticAge
   */

  const DobToAgeConverter = (dateString: any) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setEpigenticAge(age);
    return age;
  };

  useEffect(() => {
    getScoreBoaradDetails();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="MainPannel user-main-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-4 ">
                <div className="component-section">
                  <div
                    className="imgDiv progressBar"
                    onClick={() => navigateWithData(0)}
                  >
                    <ProgressBar
                      percentage={
                        userData
                          ? Math.round(userData["cardiovascularSystem"])
                          : 0
                      }
                      size={"36"}
                    />
                  </div>
                  <h6 className="progressbar-component-heading">
                    Cardiovascular System
                  </h6>
                </div>
              </div>

              <div className="col-md-4 col-4">
                <div className="component-section">
                  <div
                    className="imgDiv progressBar"
                    onClick={() => navigateWithData(1)}
                  >
                    <ProgressBar
                      percentage={
                        userData
                          ? Math.round(userData["hormonesMetabolism"])
                          : 0
                      }
                      size={"36"}
                    />
                  </div>
                  <h6 className="progressbar-component-heading ">
                    Hormones & Metabolism
                  </h6>
                </div>
              </div>

              <div className="col-md-4 col-4">
                <div className="component-section">
                  <div
                    className="imgDiv progressBar "
                    onClick={() => navigateWithData(2)}
                  >
                    <ProgressBar
                      percentage={
                        userData ? Math.round(userData["respiratorySystem"]) : 0
                      }
                      size={"36"}
                    />
                  </div>
                  <h6 className="progressbar-component-heading">
                    Respiratory System
                  </h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="offset-md-2 col-md-4 col-6">
                <div className="component-section component-section1">
                  <div
                    className="imgDiv progressBar bigCircle"
                    onClick={() => navigateWithData(3)}
                  >
                    <ProgressBar
                      percentage={
                        userData?.epigeneticAge
                      }
                      size={"43"}
                      checkAge={
                        userData &&
                        userData["epigeneticAge"]  <= epigenticAge
                        ? "#27AE60"
                        : "#EB5757"
                        
                      }
                      isLongBar={true}
                    />
                  </div>
                  <h5 className="progressbar-component-heading bigHeading">
                    Epigenetic Age
                  </h5>
                </div>
              </div>

              <div className="col-md-4 offset-md-right-2 col-6">
                <div className="component-section component-section1">
                  <div
                    className="imgDiv progressBar bigCircle"
                    onClick={() => navigateWithData(4)}
                  >
                    <ProgressBar
                      percentage={
                        userData ? Math.round(userData["hupeHealthScore"]) : 0
                      }
                      size={"43"}
                      isLongBar={true}
                    />
                  </div>
                  <h5 className="progressbar-component-heading bigHeading">
                    Hupe Health Score
                  </h5>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-4">
                <div className="component-section">
                  <div
                    className="imgDiv progressBar"
                    onClick={() => navigateWithData(5)}
                  >
                    <ProgressBar
                      percentage={
                        userData
                          ? Math.round(userData["energyMitochondria"])
                          : 0
                      }
                      size={"36"}
                    />
                  </div>
                  <h6 className="progressbar-component-heading ">
                    Energy / Mitochondrial
                  </h6>
                </div>
              </div>

              <div className="col-md-4 col-4">
                <div className="component-section">
                  <div
                    className="imgDiv progressBar"
                    onClick={() => navigateWithData(6)}
                  >
                    <ProgressBar
                      percentage={userData ? Math.round(userData["kidney"]) : 0}
                      size={"36"}
                    />
                  </div>
                  <h6 className="progressbar-component-heading ">Kidney</h6>
                </div>
              </div>
              <div className="col-md-4 col-4">
                <div className="component-section">
                  <div
                    className="imgDiv progressBar"
                    onClick={() => navigateWithData(7)}
                  >
                    <ProgressBar
                      percentage={
                        userData
                          ? Math.round(userData["longevityInflammation"])
                          : 0
                      }
                      size={"36"}
                    />
                  </div>
                  <h6 className="progressbar-component-heading ">
                    Longevity / Inflammation
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScoreBoard;
