import "./graphshared.style.scss";
import { Line } from "react-chartjs-2";
interface IProps {
  data?: any;
  graphTitle?: any;
  scaleY?: boolean;
  scaleX?: boolean;
  scaleXFontSize?: boolean;
  hrLines?: boolean;
  scoreChild?: any;
  grHeight?: any;
}

const GraphShared: React.FC<IProps> = ({
  data,
  graphTitle,
  scaleY,
  scaleX,
  scaleXFontSize,
  grHeight,
}) => {
  const options = {
    legend: {
      display: false,
    },

    title: {
      display: false,
      text: "",
    },
    layout: {
      padding: 15,
    },
    scales: {
      yAxes:
        scaleY == true
          ? [
              {
                display: false, //this will remove all the x-axis grid lines
              },
            ]
          : "",
      xAxes:
        scaleX == true
          ? [
              {
                display: false, //this will remove all the x-axis grid lines
              },
            ]
          : [
              {
                ticks: scaleXFontSize
                  ? {
                      fontSize: 14,
                      fontColor: "white",
                      fontWeight: 400,
                    }
                  : {},
              },
            ],
    },
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h4 className="KPI-PopupHeading" style={{ color: "white" }}>
          {graphTitle}
        </h4>
        <div className="parrent-line">
          <Line
            data={data}
            options={options}
            height={grHeight ? grHeight : 120}
          />
          <div className="hr-line"></div>
        </div>
      </div>
    </>
  );
};

export default GraphShared;
