import "./login.style.scss";
import ParqBg from "../../../src/assets/img/Hupe_life_form_02_PARQ_bg.png";
import LoginLogo from "../../../src/assets/img/Logo.png";
import { useState } from "react";
import { ToastOnFailure, ToastOnSuccess } from "../../shared/Toast/ToastMsg";
import { HttpCallPost } from "../../services/NetworkCall";
import { LOGIN } from "../../services/EndPoints";
import { useNavigate } from "react-router-dom";
import { DefaultRoute } from "../../routes/RouterPath";
import Spinner from "../../shared/Spinner/Spinner";

function Login(props: any) {
  const emailRegx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const LoginWithEmailPassword = (e: any) => {
    e.preventDefault();
    if (emailRegx.test(userDetails.email) && userDetails.password.length >= 6) {
      setLoading(true);
      HttpCallPost(LOGIN, {
        email: `${userDetails.email}`,
        password: `${userDetails.password}`,
      })
        .then((response: any) => {
          setLoading(false);
          if (response?.data?.status == 200) {
            if (response.data.data.user.role === "User") {
              ToastOnSuccess(response?.data?.msg);
              setUserDetails({
                email: "",
                password: "",
              });
              sessionStorage.setItem("AUTH_TOKEN", response.data.data.token);
              sessionStorage.setItem("userId", response.data.data.user.id);
              ToastOnSuccess(response?.data?.data.msg);
              props.setSigninStatus(true);
              if (sessionStorage.getItem("previousUrl")) {
                navigate(`${sessionStorage.getItem("previousUrl")}`);
              } else {
                navigate(DefaultRoute, { replace: true });
              }
            } else {
              ToastOnFailure(
                "You have entered an invalid username or password"
              );
            }
          } else {
            ToastOnFailure(response?.data?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          ToastOnFailure(error?.response?.data?.message);
        });
    } else {
      ToastOnFailure("please Fill the Details :)");
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <form id="myform" onSubmit={(e: any) => LoginWithEmailPassword(e)}>
            <div className="bgMain">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 mobileView">
                    <img className="bgMainImg" src={ParqBg} alt="" />
                  </div>
                  <div className="col-md-4 LoginSec">
                    <div className="logo">
                      <img className="logoImage" src={LoginLogo} alt="" />
                    </div>
                    <h2 className="logIn-Heading">Log in to your dashboard</h2>

                    <div className="inputDiv">
                      <div className="mb-3">
                        <div className="inputField">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            autoComplete="off"
                            placeholder="Email"
                            value={userDetails.email}
                            onChange={(e: any) =>
                              setUserDetails({
                                ...userDetails,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="inputField">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="exampleInputPassword1"
                            autoComplete="off"
                            placeholder="Password"
                            value={userDetails.password}
                            onChange={(e: any) =>
                              setUserDetails({
                                ...userDetails,
                                password: e.target.value,
                              })
                            }
                          />
                          <p className="showHide" onClick={togglePassword}>
                            {userDetails.password.length > 0 ? (
                              <>{!showPassword ? "Show" : "Hide"}</>
                            ) : (
                              <></>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="logBtnDiv">
                        <input
                          disabled={
                            emailRegx.test(userDetails.email) &&
                            userDetails.password.length >= 6
                              ? false
                              : true
                          }
                          className={`btn btn-secondary LogInBtn`}
                          type="submit"
                          id="mysubmit"
                          value="Log In"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
export default Login;
