import { useEffect, useState } from "react";
import ModelPopUp from "../../shared/Model/ModelPopUp";
import RedArrow from "../../assets/img/Line 92.png";
import GreenArrow from "../../assets/img/Line 93.png";
import GraphShared from "../../shared/GraphShared/GraphShared";
import "./kpi.style.scss";
import { KPI } from "../../services/EndPoints";
import { HttpCallGet } from "../../services/NetworkCall";
import Spinner from "../../shared/Spinner/Spinner";
import { capitalizeFirstLetter } from "../../util/util";

const KpiPage = () => {
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphTitle, setGraphTitle] = useState("");
  const [kpiData, setKpiData] = useState<any>([]);
  const [activeId, setActiveId] = useState<any>();
  const [targetGraphArr, setTargetGraphArr] = useState<any>([]);
  const [actulTargetGraphArr, setActualGraphArr] = useState<any>([]);

  const graphData = {
    labels: ["Now", "Phase 1", "Phase 2", "Phase 3"],
    datasets: [
      {
        label: "Phases",
        data: actulTargetGraphArr.slice(2, 6),
        fill: true,
        borderColor: "#27AE60",
      },
      {
        label: "Data",
        data: targetGraphArr.slice(2, 6),
        fill: false,
        borderColor: "#EB5757",
      },
    ],
  };

  /**
   * @param arr -array of obejct
   * @param id -identify the specific item in object
   * @returns graphActiveTitle name
   */
  const setActiveKpiTitleHandler = (arr: any, id: any) => {
    return arr
      .map((item: any) => {
        if (item.id === id) {
          return capitalizeFirstLetter(item.kpiMetricDetail);
        }
      })
      .join("");
  };

  /**
   *
   * @param arr
   * @param id
   * @returns two array (actual & target)
   */
  const getActualAndTargetHandler = (arr: any, id: any) => {
    let actulArr: any = [];
    let targetArr: any = [];
    arr.map((item: any) => {
      if (item.id == id) {
        targetArr.push(item.id);
        targetArr.push(item.kpiMetricDetail);
        targetArr.push(item.now);
        targetArr.push(item.phase1);
        targetArr.push(item.phase2);
        targetArr.push(item.phase3);
        targetArr.push(item.unit);
        actulArr.push(item.id);
        actulArr.push(item.kpiMetricDetail);
        actulArr.push(item.now);
        actulArr.push(item.actualPhase1);
        actulArr.push(item.actualPhase2);
        actulArr.push(item.actualPhase3);
        actulArr.push(item.unit);
      }
    });
    setTargetGraphArr(targetArr);
    setActualGraphArr(actulArr);
    return { targetArr: targetArr, actualArr: actulArr };
  };


  /**
   * @param activeArray - -- array of object
   * @param id -identify the specific item in object
   * onClick handler supplies new Arrary to graph & actual, target
   */

  const getAllHealthValue = (activeArray?: any, id?: any) => {
    setActiveId(id);
    getActualAndTargetHandler(activeArray, id);
    if (window.innerWidth < 992) {
      setShowModel(true);
    }
    setGraphTitle(setActiveKpiTitleHandler(activeArray, id));
  };

  const getKpiDetails = () => {
    setLoading(true);
    HttpCallGet(KPI, sessionStorage.getItem("AUTH_TOKEN"))
      .then((response: any) => {
        setLoading(false);
        if (response.data.status === 200) {
          setKpiData(response.data.data);
          getActualAndTargetHandler(
            response.data.data,
            response.data.data[0]?.id
          );
          setGraphTitle(
            setActiveKpiTitleHandler(
              response.data.data,
              response.data.data[0]?.id
            )
          );
          getActualAndTargetHandler(
            response.data.data,
            response.data.data[0]?.id
          );
          setActiveId(response.data.data[0]?.id);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  /**
   * filter the response based on @actual  & @target
   * @param actual
   * @param target
   * @returns
   */
  const showKpiField = (actual: any, target: any) => {
    return actual != null ? actual : target;
  };


  const colourHandler = (actual: any, target: any, flag: boolean) => {
    if(actual === null) {
      return '#676D7C'
    }

    if(flag){
      return actual >= target ? '#27AE60' : '#EB5757';
    }else {
      return actual <= target ? '#27AE60' : '#EB5757';
    }
  };

  useEffect(() => {
    getKpiDetails();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="MainPannel spaceN2 bgclr">
          <div className="container-fluid">
            <div className="row d-lg-block">
              <div className="col-md-12">
                <div className="headdiv">
                  <h3 className="heading3">Health KPI's</h3>
                </div>
              </div>
            </div>
            <div className="row bluod-result">
              <div className="col-lg-8">
                <div className="t-r kpitable table-responsive">
                  <table id="kpitable" className="table">
                    <thead>
                      <tr>
                        <th>KPI Metric</th>
                        <th>Now</th>
                        <th>Phase 1</th>
                        <th>Phase 2</th>
                        <th>Phase 3 </th>
                      </tr>
                    </thead>
                    <tbody className="kpi-pointer-check">
                      {kpiData ? (
                        kpiData.map((item: any, index: any) => {
                          return (
                            <tr
                            key={item.id}
                            onClick={() =>
                              getAllHealthValue(kpiData, item.id)
                            }
                            className={`${
                              activeId == item.id ? `isActive-heading kpi-matric-name` : `kpi-matric-name`
                            }`}
                          >
                            <td className="item-kpi-metric-name">
                              {capitalizeFirstLetter(item.kpiMetricDetail)}
                            </td>
                            <td className="td-kpi-item">
                              {item.now} <span> {item.unit} </span>
                            </td>
                            <td
                              className="td-kpi-item"
                              style={{
                                color: `${colourHandler(
                                  item.actualPhase1,
                                  item.phase1,
                                  item.phase1 > item.now
                                )}`,
                              }}
                            >
                              {showKpiField(item.actualPhase1, item.phase1)}
                              <span className="ms-1">{item.unit}</span>
                            </td>
                            <td
                              className="td-kpi-item"
                              style={{
                                color: `${colourHandler(
                                  item.actualPhase2,
                                  item.phase2,
                                  item.phase2 > item.now
                                )}`,
                              }}
                            >
                              {showKpiField(item.actualPhase2, item.phase2)}
                              <span className="ms-1">{item.unit}</span>
                            </td>
                            <td
                              className="td-kpi-item"
                              style={{
                                color: `${colourHandler(
                                  item.actualPhase3,
                                  item.phase3,
                                  item.phase3 > item.now
                                )}`,
                              }}
                            >
                              {showKpiField(item.actualPhase3, item.phase3)}
                              <span className="ms-1">{item.unit}</span>
                            </td>
                          </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      <tr>
                        <th></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-lg-4 kpi-graph-shared KpiTop">
                {kpiData.length > 0 && (
                  <>
                    <GraphShared
                      data={graphData}
                      graphTitle={graphTitle}
                      scaleY={true}
                      scaleXFontSize={true}
                    />
                    <br />
                    <img src={RedArrow} alt="green arrow" />
                    <span className="is-target"> Target</span>
                    <br />
                    <img src={GreenArrow} alt="red arrow" />
                    <span className="is-target">Actual</span>
                  </>
                )}

                <ModelPopUp
                  scaleY={true}
                  showValue={showModel}
                  graphData={graphData}
                  showMethod={() => setShowModel(!showModel)}
                  arrow={true}
                  graphTitle={graphTitle}
                  scaleXFontSize={true}
                ></ModelPopUp>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KpiPage;
