import './App.css';
import './assets/css/responsive.scss'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import RoutePath from './routes/AppRoute';
function App() {
  return (
    <div className="App">
     <RoutePath />
    </div>
  );
}

export default App;
