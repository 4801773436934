import { Modal } from "react-bootstrap";
import "./model.style.scss";
interface IProps {
  show: any;
  onHide: any;
}

const ModelReport: React.FC<IProps> = ({ show, onHide }) => {
  function checkAll(e: any) {
    let checkboxes = document.querySelectorAll("input[type = 'checkbox']");
    if (e.target.checked == true) {
      checkboxes.forEach((checkbox: any) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox: any) => {
        checkbox.checked = false;
      });
    }
  }

  const checkParrentChecked = (e: any) => {
    let checkboxes = document.querySelectorAll("#flexCheckDefaultMain");

    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="reportHeading"
          >
            Report generation
          </Modal.Title>
        </Modal.Header>
        <hr className="modalHR" />
        <Modal.Body>
          <div className="reportsec">
            <div className="rp-sec">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefaultMain"
                  onChange={(e: any) => checkAll(e)}
                />
                <label
                  className="form-check-label modalHeading38"
                  htmlFor="flexCheckDefaultMain"
                >
                  38 of 38
                </label>
              </div>
              <a>
                <span className="btn-greeen">Generate 55</span>
              </a>
            </div>
            <div className="rp-sec2">
              <ul className="repot-list">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault1"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault1"
                    >
                      All parameters Total bilirubin
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault2"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault2"
                    >
                      Gamma-glutamyltransferase (GGT)
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault3"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault3"
                    >
                      Alkaline phosphatase
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault4"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault4"
                    >
                      Aspartate aminotransferase (AST)
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault5"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault5"
                    >
                      Alanine aminotransferase (ALT)
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault6"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault6"
                    >
                      Amylase
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault7"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault7"
                    >
                      C-reactive protein (CRP)
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault8"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault8"
                    >
                      Total protein
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault9"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault9"
                    >
                      Albumin
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault10"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault10"
                    >
                      Globulin
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault11"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault11"
                    >
                      Uric acid
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault12"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault12"
                    >
                      Triglycerides
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault13"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault13"
                    >
                      Total cholesterol
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault14"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault14"
                    >
                      HDL-cholesterol ('good' cholesterol)
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault15"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault15"
                    >
                      LDL-cholesterol ('bad' cholesterol)
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault16"
                      onChange={(e) => checkParrentChecked(e)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault16"
                    >
                      Non-HDL cholesterol
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModelReport;
