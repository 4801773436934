import Modal from "react-bootstrap/Modal";
import "./model.style.scss";
import RedArrow from "../../assets/img/Line 92.png";
import GreenArrow from "../../assets/img/Line 93.png";
import GraphShared from "../GraphShared/GraphShared";
interface IProps {
  showValue?: boolean;
  showMethod?: any;
  data?: any;
  children?: any;
  graphData?: any;
  lOne?: any;
  arrow?: any;
  graphTitle?: any;
  scaleX?: boolean;
  scaleY?: boolean;
  scaleXFontSize?: boolean;
}
const ModelPopUp: React.FC<IProps> = ({
  showValue,
  showMethod,
  data,
  children,
  graphData,
  arrow,
  graphTitle,
  scaleX,
  scaleY,
  scaleXFontSize,
}) => {
  const scaleXvalue: any = scaleX;
  const scaleYvalue: any = scaleY;


  const statusFlag = (e: any) => {
    if (e.results < e.optimalLow) {
      return "Low";
    }

    if (e.results > e.optimalHigh) {
      return "High";
    }

    return "Normal";
  };

  return (
    <>
      <Modal
        show={showValue}
        size="lg"
        onHide={showMethod}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={showMethod}>
          <h2 className="PopupHeading">{(graphTitle)}</h2>
        </Modal.Header>
        <Modal.Body>
          <GraphShared
            data={graphData}
            scaleX={scaleXvalue}
            scaleY={scaleYvalue}
            scaleXFontSize={scaleXFontSize}
          />
          {arrow ? (
            <>
              <br />
              <img src={RedArrow} alt="green arrow" />
              <span className="is-target"> Target</span>
              <br />
              <img src={GreenArrow} alt="red arrow" />
              <span className="is-target">Actual</span>
              <br />
              <br />
            </>
          ) : null}
          {children == true ? (
            <div className="bt-table table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="FixedHeadingPop">Date</th>
                    {data &&
                      data.map(
                        (item1: any, index: any) => {
                          const { userTestDate } = item1;
                          return (
                            <th scope="col">
                              {userTestDate.reportDate}
                            </th>
                          );
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="FixedHeadingPop">Result</th>
                    {data &&
                      data.map(
                        (item1: any, index: any) => {
                          const { results, unit } = item1;
                          return (
                            <td className="resultTable ">{`${results} ${unit}`}</td>
                          );
                        }
                      )}
                  </tr>
                  <tr>
                    <th className="FixedHeadingPop">Optimal range </th>
                    {data &&
                      data.map(
                        (item1: any, index: any) => {
                          const { optimalHigh, optimalLow } =
                            item1;
                          return (
                            <td className="resultTable optimal-range">{`${optimalLow} - ${optimalHigh}`}</td>
                          );
                        }
                      )}
                  </tr>
                  <tr>
                    <th className="FixedHeadingPop">Status</th>
                    {data &&
                      data.map(
                        (item1: any, index: any) => {
                          return (
                            <td
                              className={
                                statusFlag(item1) === "Normal"
                                  ? "greenclr"
                                  : "redclr"
                              }
                            >
                              {statusFlag(item1)}
                            </td>
                          );
                        }
                      )}
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModelPopUp;
