export function capitalizeFirstLetter(str:string) {
    let str2 = str.toLocaleLowerCase();
    return str2.charAt(0).toUpperCase() + str2.slice(1);
  }


  export function formatDate(d:any) {
    let day = d.substring(8,10);
    let month = d.substring(5,7);
    let year = d.substring(0,4);
    let returnMonth = ""
    switch(Number(month)){
      case 1:
        returnMonth= "Jan"
        break
      case 2:
        returnMonth= "Feb"
        break
        
      case 3: 
        returnMonth= "Mar"
        break
        
      case 4:
        returnMonth= "Apr"
        break
        
      case 5:
        returnMonth= "May"
        break
        
      case 6:
        returnMonth= "Jun"
        break
        
      case 7: 
        returnMonth= "Jul"
        break
        
      case 8:
        returnMonth= "Aug"
        break
        
      case 9:
        returnMonth= "Sep"
        break
        
      case 10:
        returnMonth= "Oct"
        break
        
      case 11: 
        returnMonth= "Nov"
        break
        
      case 12:
        returnMonth= "Dec"  
        break
        
      default:
        return
    }
    return (day) + " " +returnMonth + " "+ year
    
    }


    export const prctGenerate = (data:any) =>{
      if(data==0){
        return 0 
      }
      return parseInt(((20 / 100) * data).toFixed(2));
    }