import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

//Toaster for Sucessfully Auth
export const ToastOnSuccess = (str: string) => {
  toast.success(str);
};
//Toaster for Failure Auth

export const ToastOnFailure = (str: string) => {
  toast.error(str);
};
//Toaster for logout Auth
export const ToastOnLogout = (str: string) => {
    toast.info(str);
  };



